import * as React from 'react';
import { GlobalStyle } from './Layout.styles';
import '@fontsource/raleway';

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle theme='dark' />
      {children}
    </React.Fragment>
  );
}
