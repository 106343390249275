import { breakpoint } from './breakpoints';

export const spaPadding = `
  padding: 15vh 32px 25vh;

  @media ${breakpoint.sm} {
    padding: 25vh 40px;
  }

  @media ${breakpoint.md} {
    padding: 25vh 80px;
  }
`;
