import styled from 'styled-components';
import { defaultTheme, fontWeight, typeScale } from '../../utils';

export const ButtonBase = styled.button`
  align-items: center;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  font-size: ${typeScale.regular};
  font-weight: ${fontWeight.bold};
  gap: 1rem;
  padding: 12px 24px;
`;

export const PrimaryButton = styled(ButtonBase)`
  background-color: ${defaultTheme.secondaryColor};
  color: ${defaultTheme.textColorOnPrimary};
  transition: background-color 400ms;

  &:hover {
    background-color: ${defaultTheme.primaryColor};
  }
`;

export const SecondaryButton = styled(ButtonBase)`
  align-self: flex-end;
  background-color: transparent;
  color: ${defaultTheme.textColor};
  padding: 0;
  transition: color 400ms;

  &:hover {
    color: ${defaultTheme.primaryColor};
  }
`;
