import styled from 'styled-components';
import { breakpoint, spaPadding, fontWeight, typeScale } from '../../utils';

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 108rem;
  ${spaPadding};

  @media ${breakpoint.md} {
    grid-template-columns: 64rem 1fr;
  }
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.h1`
  font-size: ${typeScale.heading2};
  font-weight: ${fontWeight.bold};
  margin-bottom: 1.2rem;
  line-height: 1.2;
  max-width: 44rem;

  @media ${breakpoint.md} {
    max-width: 80rem;
    font-size: ${typeScale.heading1};
    margin-bottom: 1.6rem;
  }
`;

export const Paragraph = styled.p`
  font-size: ${typeScale.regular};
  margin-bottom: 2.4rem;
`;
