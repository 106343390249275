const size = {
  sm: '540px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
};

const orientation = {
  landscape: `landscape`,
  portrait: `portrait`,
};

export const breakpoint = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg}) and (orientation: ${orientation.landscape})`,
  xl: `(min-width: ${size.xl}) and (orientation: ${orientation.landscape})`,
};
