export const green = {
  100: '#E4FFD8',
  500: '#339E00',
};

export const red = {
  500: '#4F2E2E',
};

export const neutral = {
  100: '#ffffff',
  // 200: '#f4f5f7',
  // 300: '#e1e1e1',
  400: '#1B2026',
  500: '#161B21',
};
