import React from 'react';
import { Container, Heading, Paragraph, Content } from './Home.styles';
import { Button } from '../Button';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { defaultTheme } from '../../utils';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { LogoText } from '../Logo/Logo.styles';

const Home = ({ id }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "home-bg.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <div id={id}>
      <BackgroundImage fluid={imageData} style={{ zIndex: 300 }}>
        <Container>
          <Content>
            <Heading>Leap Towards Something Bigger</Heading>
            <Paragraph>
              We use cutting edge technologies to enhance your business with
              custom solutions and services ranging from software development to
              cloud infrastructure migrations.{' '}
            </Paragraph>
            <Button
              buttonText='Learn More'
              hasIcon={true}
              buttonIcon='arrow_right'
              iconFill={defaultTheme.textColorInverted}
              type='primary'
              handleButtonClick={() => scrollTo('#services')}
            />
          </Content>
          <div></div>
        </Container>
      </BackgroundImage>
    </div>
  );
};

export default Home;
