import React from 'react';
import {
  Container,
  Wrapper,
  Heading,
  Block,
  Paragraph,
  AvatarStack,
  DecorativeTextContainer,
  DecorativeText,
  DecorativeTextTransform,
} from './About.styles';
import { Button } from '../Button';
import { Avatar } from '../Avatar';
import { defaultTheme } from '../../utils';
import Frog1 from '../../assets/images/frog1.png';
import Frog2 from '../../assets/images/frog2.png';
import Frog3 from '../../assets/images/frog3.png';
import Frog4 from '../../assets/images/frog4.png';

const About = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Container>
        <Heading>The Pondfrog Team</Heading>
        <Block />
        <Paragraph>
          We're a tight-knit team of designers and developers that know how to
          cut through the pond scum. Our team has experience designing and
          deploying scalable, fault-tolerant, and user-centered applications for
          businesses of all sizes. While you focus on your business, we focus on
          developing software that will allow you to scale and evolve.
        </Paragraph>
        <AvatarStack>
          <Avatar image={Frog1} />
          <Avatar image={Frog2} />
          <Avatar image={Frog3} />
          <Avatar image={Frog4} />
        </AvatarStack>
        {/* TODO: Add Join The Team  */}
        <span></span>
        {/* <Button
          buttonText='JOIN THE TEAM'
          hasIcon={true}
          buttonIcon='arrow_right'
          iconFill={defaultTheme.textColor}
          type='secondary'
        /> */}
        <DecorativeTextContainer aria-hidden>
          <DecorativeText>
            Innovation through bespoke engineering
          </DecorativeText>
          <DecorativeTextTransform>
            Innovation through bespoke engineering
          </DecorativeTextTransform>
        </DecorativeTextContainer>
      </Container>
    </Wrapper>
  );
};

export default About;
