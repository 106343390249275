export const primaryFont = '"Raleway", sans-serif';

export const typeScale = {
  heading1: '5.8rem',
  heading2: '3.8rem',
  heading3: '2.8rem',
  heading4: '2.4rem',
  large: '2rem',
  regular: '1.8rem',
  base: '1.6rem',
  small: '1.4rem',
  helper: '1.2rem',
  copyright: '0.9rem',
};

export const fontWeight = {
  regular: 400,
  bold: 700,
};
