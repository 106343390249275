import { green, red, neutral } from './colors';
import { primaryFont } from './typography';

export const defaultTheme = {
  primaryColor: green[500],
  primaryColorHover: green[400],
  primaryColorActive: green[300],
  secondaryColor: green[100],
  tertiaryColor: red[500],
  textColorOnPrimary: neutral[500],
  textColor: neutral[100],
  textColorInverted: neutral[500],
  textAccent: green[100],
  textAccentInverted: green[500],
  backgroundColor: neutral[500],
  backgroundColorTint: neutral[400],
  primaryFont,
};
