import React from 'react';
import { Container, Wrapper, Divider, Copyright } from './Footer.styles';
import { Logo } from '../Logo';

const Footer = () => {
  return (
    <Wrapper>
      <Divider />
      <Container>
        <Logo />
        <Copyright>
          © 2021 PondFrog Consulting, LLC. All rights reserved.
        </Copyright>
      </Container>
    </Wrapper>
  );
};

export default Footer;
