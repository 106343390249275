import React from 'react';
import {
  Container,
  Wrapper,
  Heading,
  Paragraph,
  ContactBlock,
  EmailLink,
  SocialContainer,
  IconContainer,
  AddressBlock,
} from './Contact.styles';
import { Icon } from '../Icon';
import { defaultTheme } from '../../utils';

const Contact = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Container>
        <Heading>Contact</Heading>

        <Paragraph>
          Want to work together? Email us to schedule a time to discuss your
          project needs.
        </Paragraph>

        <ContactBlock>
          <EmailLink href='mailto:hello@pondfrogconsulting.com'>
            hello@pondfrogconsulting.com
          </EmailLink>
        </ContactBlock>

        <AddressBlock>
          <p>14260 W Newberry Rd #127</p>
          <p>Newberry, FL 32669</p>
        </AddressBlock>

        <SocialContainer>
          {/* TODO: Add Social Links */}
          {/* <p>Follow Us</p>
          <IconContainer>
            <Icon icon='facebook' fill={defaultTheme.primaryColor} />
            <Icon icon='linkedin' fill={defaultTheme.primaryColor} />
            <Icon icon='twitter' fill={defaultTheme.primaryColor} />
          </IconContainer> */}
        </SocialContainer>
      </Container>
    </Wrapper>
  );
};

export default Contact;
