import styled from 'styled-components';
import { breakpoint, fontWeight, typeScale, defaultTheme } from '../../utils';

export const Wrapper = styled.div`
  background-color: ${defaultTheme.backgroundColor};
`;

export const Container = styled.div`
  display: grid;
  gap: 2.8rem;
  grid-template-areas:
    'heading'
    'paragraph'
    'contact'
    'address'
    'social';
  margin: 0 auto;
  max-width: 108rem;
  padding: 12vh 32px;

  @media ${breakpoint.sm} {
    padding: 12vh 40px;
  }

  @media ${breakpoint.md} {
    grid-template-areas:
      'heading heading .'
      'paragraph paragraph .'
      'contact address social';
    padding: 12vh 80px;
  }

  @media ${breakpoint.lg} {
    grid-template-areas:
      'heading contact social'
      'paragraph address .';
  }
`;

export const Heading = styled.h2`
  color: ${defaultTheme.textAccent};
  font-size: ${typeScale.heading2};
  font-weight: ${fontWeight.regular};
  line-height: 1.2;
  text-transform: uppercase;
  grid-area: heading;
`;

export const Paragraph = styled.p`
  line-height: 1.8;
  max-width: 55rem;
  grid-area: paragraph;
`;

export const ContactBlock = styled.div`
  color: ${defaultTheme.textAccent};
  line-height: 1.8;
  grid-area: contact;
`;

export const EmailLink = styled.a`
  color: ${defaultTheme.textAccent};
  text-decoration: none;

  &:hover {
    color: ${defaultTheme.textColor};
  }
`;

export const AddressBlock = styled.div`
  color: ${defaultTheme.textAccent};
  line-height: 1.8;
  grid-area: address;
`;

export const SocialContainer = styled.div`
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  line-height: 1.8;
  grid-area: social;

  @media ${breakpoint.lg} {
    justify-self: flex-end;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 10rem;
  justify-content: space-between;
`;
