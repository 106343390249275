import styled from 'styled-components';
import { fontWeight, typeScale } from '../../utils';

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoText = styled.p`
  margin-left: 12px;
  font-weight: ${fontWeight.bold};
  font-size: ${typeScale.large};
`;
