import './reset.css';
import { createGlobalStyle } from 'styled-components';
import { defaultTheme, typeScale, fontWeight } from '../../utils';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  body {
    color: ${({ theme }) =>
      theme === 'dark'
        ? `${defaultTheme.textColor}`
        : `${defaultTheme.textColorInverted}`};
    background-color: ${({ theme }) =>
      theme === 'dark'
        ? `${defaultTheme.textColorInverted}`
        : `${defaultTheme.textColor}`};
    font-family: ${defaultTheme.primaryFont};
    font-size: ${typeScale.base};
    font-weight: ${fontWeight.regular};
    letter-spacing: .5px;
    margin: 0 auto;
  }

  button {
    border: none;
    font-family: ${defaultTheme.primaryFont};
    font-weight: ${fontWeight.regular};
    letter-spacing: .5px;
    outline: none;
  }
`;
