import styled from 'styled-components';
import { breakpoint, fontWeight, typeScale, defaultTheme } from '../../utils';

export const Wrapper = styled.div`
  background-color: ${defaultTheme.backgroundColor};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 108rem;
  padding: 0 32px 10vh;

  @media ${breakpoint.sm} {
    padding: 0 40px 10vh;
  }

  @media ${breakpoint.md} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 80px 10vh;
  }
`;

export const Divider = styled.div`
  background-color: ${defaultTheme.textAccent};
  margin: 0 auto;
  height: 1px;
  margin-bottom: 80px;
  max-width: 108rem;
  opacity: 0.25;
  width: 100%;
`;

export const Copyright = styled.p`
  color: ${defaultTheme.textAccent};
  font-size: ${typeScale.small};
  font-weight: ${fontWeight.regular};
  opacity: 0.25;
  margin-top: 3.2rem;

  @media ${breakpoint.md} {
    margin-top: 0;
  }
`;
