import React from 'react';
import { Nav, Container, NavLinks, NavLink } from './Navigation.styles';
import { Logo } from '../Logo';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const Navigation = () => {
  return (
    <Nav>
      <Container>
        <Logo />
        <NavLinks>
          <NavLink onClick={() => scrollTo('#home')}>Home</NavLink>
          <NavLink onClick={() => scrollTo('#services')}>Services</NavLink>
          <NavLink onClick={() => scrollTo('#about')}>About</NavLink>
          <NavLink onClick={() => scrollTo('#contact')}>Contact</NavLink>
        </NavLinks>
      </Container>
    </Nav>
  );
};
