import styled from 'styled-components';
import {
  breakpoint,
  spaPadding,
  fontWeight,
  typeScale,
  defaultTheme,
} from '../../utils';

export const Wrapper = styled.div`
  background-color: ${defaultTheme.backgroundColorTint};
`;

export const Container = styled.div`
  display: grid;
  gap: 2.8rem;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content 1fr 1fr 1fr;
  margin: 0 auto;
  max-width: 108rem;
  ${spaPadding};

  @media ${breakpoint.lg} {
    grid-template-columns: 1fr 40.1rem;
    grid-template-rows: min-content 12rem 1fr;
  }
`;

export const Block = styled.span`
  display: none;

  @media ${breakpoint.lg} {
    display: block;
  }
`;

export const Heading = styled.h2`
  color: ${defaultTheme.textAccent};
  font-size: ${typeScale.heading4};
  font-weight: ${fontWeight.regular};
  line-height: 1.2;
  text-transform: uppercase;

  @media ${breakpoint.md} {
    font-size: ${typeScale.heading3};
  }

  @media ${breakpoint.lg} {
    font-size: ${typeScale.heading2};
  }
`;

export const Paragraph = styled.p`
  line-height: 1.8;
  max-width: 55rem;
`;

export const AvatarStack = styled.div`
  height: 100%;
`;

export const DecorativeTextContainer = styled.div`
  margin-top: 9.2rem;
  width: 30.4rem;
  z-index: 200;

  @media ${breakpoint.sm} {
    width: 40.1rem;
  }
`;

export const DecorativeText = styled.p`
  color: ${defaultTheme.tertiaryColor};
  font-size: ${typeScale.regular};
  font-weight: ${fontWeight.regular};
  text-transform: uppercase;
  line-height: 1.2;

  @media ${breakpoint.sm} {
    font-size: ${typeScale.heading4};
  }
`;

export const DecorativeTextTransform = styled(DecorativeText)`
  color: ${defaultTheme.textAccent};
  margin-top: -2rem;
  opacity: 0.25;
  transform: rotate(180deg);

  @media ${breakpoint.sm} {
    margin-top: -2.9rem;
  }
`;
