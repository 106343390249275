import * as React from 'react';
import Layout from '../components/Layout';
import { Navigation } from '../components/Navigation';
import Home from '../components/Home';
import Services from '../components/Services';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

// markup
const App = () => {
  return (
    <Layout>
      <Navigation />
      <Home id='home' />
      <Services id='services' />
      <About id='about' />
      <Contact id='contact' />
      <Footer />
    </Layout>
  );
};

export default App;
