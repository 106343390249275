import React from 'react';
import { PrimaryButton, SecondaryButton } from './Button.styles';
import { Icon } from '../Icon';

export const Button = ({
  buttonText,
  hasIcon,
  buttonIcon,
  iconFill,
  type,
  handleButtonClick,
}) => {
  if (type === 'primary') {
    return (
      <PrimaryButton onClick={handleButtonClick}>
        {buttonText}
        {hasIcon === true ? <Icon icon={buttonIcon} fill={iconFill} /> : null}
      </PrimaryButton>
    );
  }
  if (type === 'secondary') {
    return (
      <SecondaryButton onClick={handleButtonClick}>
        {buttonText}
        {hasIcon === true ? <Icon icon={buttonIcon} fill={iconFill} /> : null}
      </SecondaryButton>
    );
  }
};
