import styled from 'styled-components';
import { breakpoint, defaultTheme } from '../../utils';

export const Nav = styled.nav`
  background-color: ${defaultTheme.backgroundColorTint};
  border-bottom: 1px solid #2c3527;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 108rem;
  padding: 1.8rem 3.2rem;
  margin: 0 auto;

  @media ${breakpoint.sm} {
    padding: 1.8rem 4rem;
  }

  @media ${breakpoint.md} {
    padding: 1.8rem 8rem;
  }
`;

export const NavLinks = styled.div`
  display: none;

  @media ${breakpoint.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    width: 300px;
  }
`;

export const NavLink = styled.button`
  background: transparent;
  color: ${defaultTheme.textAccent};
  cursor: pointer;
  font-size: 1.6rem;
  opacity: 0.5;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  /* Remove this bullshit and make nav links have a proper active state */
  &:focus {
    opacity: 1;
  }
`;
