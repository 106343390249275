import React from 'react';
import { icons } from '../shared/icons';

export const Icon = ({ icon, fill }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d={icons[icon]} fill={fill} />
    </svg>
  );
};
