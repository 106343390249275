import styled from 'styled-components';
import { breakpoint, defaultTheme } from '../../utils';

export const AvatarContainer = styled.img`
  background: #353c45;
  border: solid 1px ${defaultTheme.textAccent};
  border-radius: 100px;
  display: inline-block;
  height: 8.8rem;
  margin-right: -2rem;
  width: 8.8rem;

  @media ${breakpoint.sm} {
    height: 11.8rem;
    margin-right: -2.8rem;
    width: 11.8rem;
  }
`;
