import React from 'react';
import { defaultTheme } from '../../utils';
import {
  Container,
  HeadingContainer,
  Heading,
  HeadingTransform,
  Paragraph,
  ServicesList,
  ServiceItem,
  TriangleSvg,
  ServiceText,
} from './Services.styles';
import { Icon } from '../Icon';

/**
 * working on function to animate triangle
 */
// let path;
// const animateTriangle = () => {
//   function getRandomInt(min, max) {
//     min = Math.ceil(min);
//     max = Math.floor(max);
//     return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
//   }

//   const val1 = getRandomInt(2, 4);
//   const val2 = getRandomInt(300, 420);

//   let path = `M2 487L${val1}95.325 2L397 ${val2}.179L2 487Z`;
//   return path;
// };

// animateTriangle();

const Services = ({ id }) => {
  return (
    <div id={id}>
      <Container>
        <HeadingContainer>
          <Heading>Next level strategic dev solutions</Heading>
          <HeadingTransform aria-hidden>
            Next level strategic dev solutions
          </HeadingTransform>
        </HeadingContainer>
        <ServicesList>
          <ServiceItem>
            <Icon icon='dev' fill={defaultTheme.primaryColor} />
            <ServiceText>Software Development</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Icon icon='devops' fill={defaultTheme.primaryColor} />
            <ServiceText>DevOps Engineering</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Icon icon='flow' fill={defaultTheme.primaryColor} />
            <ServiceText>UX Research & Strategy</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Icon icon='diamond' fill={defaultTheme.primaryColor} />
            <ServiceText>UI Design</ServiceText>
          </ServiceItem>
        </ServicesList>
        <Paragraph>
          We are passionate about using the latest technologies to craft
          applications that transform the way our clients do business. Whether
          you're looking to fundamentally reimagine your application, transition
          to the cloud, or redesign your existing infrastructure to decrease
          overhead our team of designers, developers, and DevOps engineers will
          bring your vision to life.
        </Paragraph>
      </Container>
      <TriangleSvg
        width='398'
        height='488'
        viewBox='0 0 398 488'
        fill='none'
        xmlns='http://www.w3.org/2000/Svg'
      >
        <path d='M2 487L295.325 2L397 387.179L2 487Z' stroke='#4F2E2E' />
      </TriangleSvg>
    </div>
  );
};

export default Services;
