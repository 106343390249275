import styled from 'styled-components';
import {
  breakpoint,
  spaPadding,
  fontWeight,
  typeScale,
  defaultTheme,
} from '../../utils';

export const Container = styled.div`
  background-color: ${defaultTheme.backgroundColor};
  display: grid;
  gap: 2.8rem;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content 1fr 1fr;
  margin: 0 auto;
  max-width: 108rem;
  ${spaPadding};

  @media ${breakpoint.lg} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const HeadingContainer = styled.div`
  z-index: 200;

  @media ${breakpoint.md} {
    width: 398px;
  }

  @media ${breakpoint.lg} {
    width: 536px;
  }
`;

export const Heading = styled.h2`
  color: ${defaultTheme.textAccent};
  font-size: ${typeScale.heading4};
  font-weight: ${fontWeight.regular};
  text-transform: uppercase;
  line-height: 1.2;

  @media ${breakpoint.md} {
    font-size: ${typeScale.heading3};
  }

  @media ${breakpoint.lg} {
    font-size: ${typeScale.heading2};
  }
`;

export const HeadingTransform = styled(Heading)`
  display: none;

  @media ${breakpoint.md} {
    display: block;
    filter: blur(1.5px);
    margin-top: -3.5rem;
    opacity: 0.25;
    transform: rotate(180deg);
  }

  @media ${breakpoint.lg} {
    margin-top: -4.5rem;
  }
`;

export const ServicesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 200;
`;

export const ServiceItem = styled.li`
  color: ${defaultTheme.textAccent};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const ServiceText = styled.p`
  font-weight: ${fontWeight.bold};
  font-size: ${typeScale.regular};
  letter-spacing: 1px;
  margin-left: 1.2rem;

  @media ${breakpoint.xl} {
    font-size: ${typeScale.large};
  }
`;

export const Paragraph = styled.p`
  font-size: ${typeScale.regular};
  z-index: 200;
`;

export const TriangleSvg = styled.svg`
  display: block;
  position: absolute;
  top: 100%;
  right: 30vw;
  z-index: 100;
`;
